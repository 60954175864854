import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDoctordToAssign } from "../../api/auth";
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  TextField,
} from "@mui/material";
import Sidebar from "../../components/sidebar/Sidebar";
import MainSection from "../../components/mainSection/MainSection";

const AssignDoctorPage = () => {
  const dispatch = useDispatch();

  const sessionNames = [
    "D0 (Health Admin)",
    "D1",
    "D2",
    "Deepana-Pachana",
    "Snehapana",
    "Abhyanga",
    "Virechana",
    "Postvirechanna",
  ];

  const [sessions, setSessions] = useState(
    sessionNames.map((name, index) => ({
      name,
      doctorId: "",
      date: "",
      status: "",
      isOptional: index === 2,
      timeslot: "",
    }))
  );

  const [showHistory, setShowHistory] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  // Fetch doctors list
  useEffect(() => {
    dispatch(getDoctordToAssign());
  }, [dispatch]);

  const { doctorsData, loading } = useSelector((state) => state.admin);

  const handleDropdownChange = (index, e) => {
    const updatedSessions = [...sessions];
    updatedSessions[index].doctorId = e.target.value;
    updatedSessions[index].status = "";
    setSessions(updatedSessions);
  };

  const handleDateChange = (index, e) => {
    const updatedSessions = [...sessions];
    updatedSessions[index].date = e.target.value;
    updatedSessions[index].status = "";
    setSessions(updatedSessions);
  };

  const handleStatusChange = (index, e) => {
    const updatedSessions = [...sessions];
    updatedSessions[index].status = e.target.value;
    setSessions(updatedSessions);
  };

  const handleTimeslotChange = (index, e) => {
    const updatedSessions = [...sessions];
    updatedSessions[index].timeslot = e.target.value;
    setSessions(updatedSessions);
  };

  const handleSubmitSession = (index) => {
    const session = sessions[index];
    if (
      session.doctorId &&
      session.date &&
      session.status &&
      session.timeslot
    ) {
      const updatedSessions = [...sessions];
      updatedSessions[index].status = "Completed";
      setSessions(updatedSessions);
      setShowConfirmationDialog(true);
    } 
  };

  const handleConfirmationDialogClose = (proceed) => {
    setShowConfirmationDialog(false);
  };

  const handleViewHistory = () => {
    setShowHistory(!showHistory);
  };

  // Generate timeslots (e.g., 10:00 - 10:30, 10:30 - 11:00, etc.)
  const generateTimeslots = () => {
    let timeslots = [];
    const startTime = 8; // 8:00 AM
    const endTime = 18; // 6:00 PM
    for (let i = startTime; i < endTime; i++) {
      const timeSlotStart = `${i}:00`;
      const timeSlotEnd = `${i}:30`;
      timeslots.push(`${timeSlotStart} - ${timeSlotEnd}`);
    }
    return timeslots;
  };

  return (
    <div className="p-6 bg-white rounded shadow-lg">
      <Sidebar />
      <MainSection>
        <div>
          <Typography variant="h5" gutterBottom>
            Virechana Package
          </Typography>

          {/* D0, D1, D2 sessions in one div */}
          <Box
            mb={3}
            sx={{
              border: "1px solid #ccc",
              padding: "16px",
              borderRadius: "8px",
            }}
          >
            {sessions.slice(0, 3).map((session, index) => (
              <Box
                key={index}
                mb={3}
                sx={{
                  border: "1px solid #ccc",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <Typography variant="body1" gutterBottom>
                  {sessionNames[index]} {session.isOptional ? "(Optional)" : ""}
                </Typography>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ flexWrap: "wrap" }} // Ensure items wrap when screen is small
                >
                  <Box sx={{ width: "48%", padding: "0 8px" }}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id={`doctor-dropdown-label-${index}`}>
                        Select Doctor
                      </InputLabel>
                      <Select
                        labelId={`doctor-dropdown-label-${index}`}
                        value={session.doctorId}
                        onChange={(e) => handleDropdownChange(index, e)}
                        label="Select Doctor"
                      >
                        {loading ? (
                          <MenuItem disabled>
                            <CircularProgress size={24} />
                          </MenuItem>
                        ) : doctorsData && doctorsData.length > 0 ? (
                          doctorsData.map((doctor) => (
                            <MenuItem key={doctor._id} value={doctor._id}>
                              {doctor.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No Doctors Available</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box sx={{ width: "48%", padding: "0 8px" }}>
                    <TextField
                      value={session.date}
                      onChange={(e) => handleDateChange(index, e)}
                      placeholder="Select Date"
                      fullWidth
                      variant="outlined"
                      type="text"
                    />
                  </Box>
                </Box>

                {/* Status and Timeslot dropdown in one line */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ flexWrap: "wrap", marginTop: "10px" }}
                >
                  <Box sx={{ width: "48%", padding: "0 8px" }}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id={`status-dropdown-label-${index}`}>
                        Status
                      </InputLabel>
                      <Select
                        labelId={`status-dropdown-label-${index}`}
                        value={session.status}
                        onChange={(e) => handleStatusChange(index, e)}
                        label="Status"
                      >
                        <MenuItem value="">Select Status</MenuItem>
                        <MenuItem value="Pending">Pending</MenuItem>
                        <MenuItem value="Completed">Completed</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Box sx={{ width: "48%", padding: "0 8px" }}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id={`timeslot-dropdown-label-${index}`}>
                        Select Timeslot
                      </InputLabel>
                      <Select
                        labelId={`timeslot-dropdown-label-${index}`}
                        value={session.timeslot}
                        onChange={(e) => handleTimeslotChange(index, e)}
                        label="Select Timeslot"
                      >
                        <MenuItem value="">Select Timeslot</MenuItem>
                        {generateTimeslots().map((slot, idx) => (
                          <MenuItem key={idx} value={slot}>
                            {slot}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>

                <Button
                  onClick={() => handleSubmitSession(index)}
                  variant="contained"
                  sx={{
                    backgroundColor: "#751b1b",
                    "&:hover": {
                      backgroundColor: "#90A4AE",
                    },
                    width: "20%",
                  }}
                  style={{ marginTop: "20px" }}
                  disabled={session.status === "Completed"}
                >
                  Submit
                </Button>
              </Box>
            ))}
          </Box>

          {/* Remaining Sessions in another div */}
          <Box
            mb={3}
            sx={{
              border: "1px solid #ccc",
              padding: "16px",
              borderRadius: "8px",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Internal Consultations
            </Typography>

            {sessions.slice(3).map((session, index) => (
              <Box
                key={index + 3}
                mb={3}
                sx={{
                  border: "1px solid #ccc",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <Typography variant="body1" gutterBottom>
                  {sessionNames[index + 3]}{" "}
                  {session.isOptional ? "(Optional)" : ""}
                </Typography>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ flexWrap: "wrap" }}
                >
                  {/* Select Doctor */}
                  <Box sx={{ width: "48%", padding: "0 8px" }}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id={`doctor-dropdown-label-${index + 3}`}>
                        Select Doctor
                      </InputLabel>
                      <Select
                        labelId={`doctor-dropdown-label-${index + 3}`}
                        value={session.doctorId}
                        onChange={(e) => handleDropdownChange(index + 3, e)}
                        label="Select Doctor"
                      >
                        {loading ? (
                          <MenuItem disabled>
                            <CircularProgress size={24} />
                          </MenuItem>
                        ) : doctorsData && doctorsData.length > 0 ? (
                          doctorsData.map((doctor) => (
                            <MenuItem key={doctor._id} value={doctor._id}>
                              {doctor.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No Doctors Available</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>

                  {/* Select Date */}
                  <Box sx={{ width: "48%", padding: "0 8px" }}>
                    <TextField
                      value={session.date}
                      onChange={(e) => handleDateChange(index + 3, e)}
                      placeholder="Select Date"
                      fullWidth
                      variant="outlined"
                      type="text"
                    />
                  </Box>
                </Box>

                {/* Status and Timeslot dropdown in one line */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ flexWrap: "wrap", marginTop: "10px" }}
                >
                  {/* Status dropdown */}
                  <Box sx={{ width: "48%", padding: "0 8px" }}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id={`status-dropdown-label-${index + 3}`}>
                        Status
                      </InputLabel>
                      <Select
                        labelId={`status-dropdown-label-${index + 3}`}
                        value={session.status}
                        onChange={(e) => handleStatusChange(index + 3, e)}
                        label="Status"
                      >
                        <MenuItem value="">Select Status</MenuItem>
                        <MenuItem value="Pending">Pending</MenuItem>
                        <MenuItem value="Completed">Completed</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  {/* Timeslot dropdown */}
                  <Box sx={{ width: "48%", padding: "0 8px" }}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id={`timeslot-dropdown-label-${index + 3}`}>
                        Select Timeslot
                      </InputLabel>
                      <Select
                        labelId={`timeslot-dropdown-label-${index + 3}`}
                        value={session.timeslot}
                        onChange={(e) => handleTimeslotChange(index + 3, e)}
                        label="Select Timeslot"
                      >
                        <MenuItem value="">Select Timeslot</MenuItem>
                        {generateTimeslots().map((slot, idx) => (
                          <MenuItem key={idx} value={slot}>
                            {slot}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>

                <Button
                  onClick={() => handleSubmitSession(index + 3)}
                  variant="contained"
                  sx={{
                    backgroundColor: "#751b1b",
                    "&:hover": {
                      backgroundColor: "#90A4AE",
                    },
                    width: "20%",
                  }}
                  style={{ marginTop: "20px" }}
                  disabled={session.status === "Completed"}
                >
                  Submit
                </Button>
              </Box>
            ))}
          </Box>
        </div>
      </MainSection>
    </div>
  );
};

export default AssignDoctorPage;
