import React, { useState } from "react";
import { BsFilterRight } from "react-icons/bs";
import { CiExport } from "react-icons/ci";
import { Opacity, Search } from "@mui/icons-material";
import Sidebar from "../../components/sidebar/Sidebar";
import MainSection from "../../components/mainSection/MainSection";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import ReactQuill from "react-quill"; // Import React Quill
import "react-quill/dist/quill.snow.css"; // Import the styles for React Quill

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  Opacity: 1,
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY:"auto"
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  Opacity: 1,
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY:"auto"
};

const Virechana = () => {
  const [packages, setPackages] = useState([
    {
      name: "Breakfast",
      stagename: "Snehapana",
      recipename: "idly",
      ingredients: "rice flour",
      description:"",
    },
    {
      name: "lunch",
      stagename: "Deepana-pachana",
      recipename: "dose",
      ingredients: "ragi flour",
      description: "123",
    },
  ]);

  const [open, setOpen] = useState(false);
  const [openAddPackage, setOpenAddPackage] = useState(false);
  const [newPackage, setNewPackage] = useState({
    name: "",
    stagename: "",
    recipename: "",
    ingredients: "",
    description: null,
    ingredients: "",
    description: "",
  });
  const [editPackage, setEditPackage] = useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [packageToDelete, setPackageToDelete] = useState(null);

  // Recipe Name options (4 options)
  const recipeOptions = ["BreakFast", "Lunch", "Evening Snacks", "Dinner"];
  // Stage Name options (5 options)
  const stageOptions = [
    "Deepana-pachana",
    "Snehapana",
    "Abhyanga",
    "Virechana",
    "Post Virechana",
  ];

  const handleAddRecipeOpen = () => {
    setOpenAddPackage(true);
  };

  const handleAddPackageClose = () => {
    setOpenAddPackage(false);
    setNewPackage({
      name: "",
      stagename: "",
      recipename: "",
      ingredients: "",
      description: null,
      ingredients: "",
      description: "",
    });
  };

  const handleNewPackageChange = (e) => {
    const { name, value } = e.target;
    setNewPackage((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNewIngredientsChange = (value) => {
    setNewPackage((prev) => ({
      ...prev,
      ingredients: value,
    }));
  };

  const handleNewDescriptionChange = (value) => {
    setNewPackage((prev) => ({
      ...prev,
      description: value,
    }));
  };

  const handleAddPackageSubmit = () => {
    if (
      !newPackage.name ||
      !newPackage.stagename ||
      !newPackage.recipename ||
      !newPackage.ingredients ||
      !newPackage.ingredients ||
      !newPackage.description
    ) {
      alert("Please fill in all fields.");
      return;
    }

    setPackages((prev) => [
      ...prev,
      { ...newPackage, _id: String(prev.length + 1) },
    ]);

    handleAddPackageClose();
  };

  const handleOpenEditModal = (pkg) => {
    setEditPackage(pkg);
    setOpen(true);
  };

  const handleCloseEditModal = () => {
    setOpen(false);
    setEditPackage(null);
  };

  const handleEditPackageChange = (e) => {
    const { name, value } = e.target;
    setEditPackage((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditIngredientsChange = (value) => {
    setEditPackage((prev) => ({
      ...prev,
      ingredients: value,
    }));
  };

  const handleEditDescriptionChange = (value) => {
    setEditPackage((prev) => ({
      ...prev,
      description: value,
    }));
  };

  const handleEditPackageSubmit = () => {
    if (
      !editPackage.name ||
      !editPackage.stagename ||
      !editPackage.recipename ||
      !editPackage.ingredients ||
      !editPackage.ingredients ||
      !editPackage.description
    ) {
      alert("Please fill in all fields.");
      return;
    }

    setPackages((prev) =>
      prev.map((pkg) => (pkg._id === editPackage._id ? editPackage : pkg))
    );

    handleCloseEditModal();
  };

  const handleOpenDeleteConfirm = (pkg) => {
    setPackageToDelete(pkg);
    setOpenDeleteConfirm(true);
  };

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
    setPackageToDelete(null);
  };

  const handleDeletePackage = () => {
    setPackages((prev) =>
      prev.filter((pkg) => pkg._id !== packageToDelete._id)
    );
    handleCloseDeleteConfirm();
  };

  return (
    <div className="p-6 bg-white rounded shadow-lg">
      <Sidebar />
      <MainSection>
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Virechana Recipe</h1>
          <button
            onClick={handleAddRecipeOpen}
            className="bg-rose-900 text-white px-4 py-2 rounded hover:bg-rose-800"
          >
            Add Recipe
          </button>
        </div>

        <div className="flex flex-wrap justify-between items-center gap-4 mb-4">
          <div className="flex items-center w-full sm:w-1/2">
            <input
              type="search"
              placeholder="Search"
              className="border-2 rounded-xl p-2 w-full"
            />
            <Search className="text-gray-500 ml-2" />
          </div>
          <div className="flex space-x-4">
            <button className="border-2 rounded-xl px-4 py-2 flex items-center gap-2 hover:bg-gray-100">
              <BsFilterRight /> Filter
            </button>
            <button className="border-2 rounded-xl px-4 py-2 flex items-center gap-2 hover:bg-gray-100">
              <CiExport /> Export
            </button>
          </div>
        </div>

        <table className="table-auto w-full text-left border-collapse text-sm">
          <thead>
            <tr className="border-b bg-gray-100">
              <th className="p-2">Name</th>
              <th className="p-2">Stage Name</th>
              <th className="p-2">Recipe Name</th>
              <th className="p-2">Ingredients</th>
              <th className="p-2">Description</th>
              <th className="p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {packages.map((pkg) => (
              <tr key={pkg._id} className="border-b hover:bg-gray-50">
                <td className="p-2">{pkg.name}</td>
                <td className="p-2">{pkg.stagename}</td>
                <td className="p-2">{pkg.recipename}</td>
                <td className="p-2">{pkg.ingredients}</td>
                <td className="p-2">{pkg.description || "No Description"}</td>
                <td className="p-2 flex items-center">
                  <button
                    onClick={() => handleOpenEditModal(pkg)}
                    className="text-xl text-red-600"
                  >
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => handleOpenDeleteConfirm(pkg)}
                    className="text-xl text-red-600 ml-4"
                  >
                    <MdDeleteForever />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </MainSection>

      {/* Modal for Adding Recipe */}
      <Modal open={openAddPackage} onClose={handleAddPackageClose}>
        <Box sx={style1}>
          <Typography variant="h6" component="h2">
            Add Recipe
          </Typography>
          <div className="mt-4">
            <label htmlFor="name" className="block mb-2 font-medium">
              Name
            </label>
            <select
              id="name"
              name="name"
              value={newPackage.name}
              onChange={handleNewPackageChange}
              className="border-2 rounded-xl p-2 w-full"
            >
              <option value="">Select </option>
              {recipeOptions.map((recipe, index) => (
                <option key={index} value={recipe}>
                  {recipe}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-4">
            <label htmlFor="stagename" className="block mb-2 font-medium">
              Stage Name
            </label>
            <select
              id="stagename"
              name="stagename"
              value={newPackage.stagename}
              onChange={handleNewPackageChange}
              className="border-2 rounded-xl p-2 w-full"
            >
              <option value="">Select Stage</option>
              {stageOptions.map((stage, index) => (
                <option key={index} value={stage}>
                  {stage}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-4">
            <label htmlFor="recipename" className="block mb-2 font-medium">
              Recipe Name
            </label>
            <input
              id="recipename"
              type="text"
              name="recipename"
              value={newPackage.recipename}
              onChange={handleNewPackageChange}
              className="border-2 rounded-xl p-2 w-full"
            />
          </div>
          <div className="mt-4">
            <label htmlFor="ingredients" className="block mb-2 font-medium">
              Ingredients
            </label>
            <ReactQuill
              value={newPackage.ingredients}
              onChange={handleNewIngredientsChange}
              className="border-2 rounded-xl w-full"
            />
          </div>
          <div className="mt-4">
            <label htmlFor="description" className="block mb-2 font-medium">
              Description
            </label>
            <ReactQuill
              value={newPackage.description}
              onChange={handleNewDescriptionChange}
              className="border-2 rounded-xl w-full"
            />
          </div>
          <div className="mt-4">
            <button
              onClick={handleAddPackageSubmit}
              className="bg-rose-900 text-white px-4 py-2 rounded hover:bg-rose-800"
            >
              Add Recipe
            </button>
            <button
              onClick={handleAddPackageClose}
              className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-500 ml-4"
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>

      {/* Modal for Editing Recipe */}
      <Modal open={open} onClose={handleCloseEditModal}>
        <Box sx={style1}>
          <Typography variant="h6" component="h2">
            Edit Recipe: {editPackage?.name}
          </Typography>
          <div className="mt-4">
            <label htmlFor="name" className="block mb-2 font-medium">
              Recipe Name
            </label>
            <select
              id="name"
              name="name"
              value={editPackage?.name}
              onChange={handleEditPackageChange}
              className="border-2 rounded-xl p-2 w-full"
            >
              <option value="">Select </option>
              {recipeOptions.map((recipe, index) => (
                <option key={index} value={recipe}>
                  {recipe}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-4">
            <label htmlFor="stagename" className="block mb-2 font-medium">
              Stage Name
            </label>
            <select
              id="stagename"
              name="stagename"
              value={editPackage?.stagename}
              onChange={handleEditPackageChange}
              className="border-2 rounded-xl p-2 w-full"
            >
              <option value="">Select Stage</option>
              {stageOptions.map((stage, index) => (
                <option key={index} value={stage}>
                  {stage}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-4">
            <label htmlFor="recipename" className="block mb-2 font-medium">
              Recipe Name
            </label>
            <input
              id="recipename"
              type="text"
              name="recipename"
              value={editPackage?.recipename}
              onChange={handleEditPackageChange}
              className="border-2 rounded-xl p-2 w-full"
            />
          </div>
          <div className="mt-4">
            <label htmlFor="ingredients" className="block mb-2 font-medium">
              Ingredients
            </label>
            <ReactQuill
              value={editPackage?.ingredients}
              onChange={handleEditIngredientsChange}
              className="border-2 rounded-xl w-full"
            />
          </div>
          <div className="mt-4">
            <label htmlFor="description" className="block mb-2 font-medium">
              Description
            </label>
            <ReactQuill
              value={editPackage?.description}
              onChange={handleEditDescriptionChange}
              className="border-2 rounded-xl w-full"
            />
          </div>
          <div className="mt-4">
            <button
              onClick={handleEditPackageSubmit}
              className="bg-rose-900 text-white px-4 py-2 rounded hover:bg-rose-800"
            >
              Update Recipe
            </button>
            <button
              onClick={handleCloseEditModal}
              className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-500 ml-4"
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>

      {/* Modal for Deleting Recipe */}
      <Modal open={openDeleteConfirm} onClose={handleCloseDeleteConfirm}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Are you sure you want to delete this recipe?
          </Typography>
          <div className="mt-4">
            <button
              onClick={handleDeletePackage}
              className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-800"
            >
              Delete
            </button>
            <button
              onClick={handleCloseDeleteConfirm}
              className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-500 ml-4"
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Virechana;
