import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";
import { toast } from "react-toastify";

// Retrieve token from localStorage
const token = localStorage.getItem("token") || null;

// Define initial state
export const initialState = {
  isAuthenticate: !!token, // Convert token to boolean directly
  hasError: false,
  adminToken: token,
  loading: false,
  admin: null, // Store admin profile data
  message: null, // Store messages from actions
  doctors: [], // Store the list of doctors
  health_admin: [],
  pharma_admin: [],
  one_doctor: null,
  one_health_admin: null,
  one_pharma_admin: null,
  status: "",
  patie: [],
  banner: [],
  one_banner: null,
  blog: [],
  one_blog: null,
  treatmentList: null,
  packagesList: null,
  packageBookings: null,
  doctorsData: null
};

// Define user slice with reducers
export const userSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    getLoading: (state) => {
      state.loading = true;
    },
    getUserFailure: (state) => {
      state.hasError = true;
      state.loading = false;
    },
    getLocalStorageToken: (state, { payload }) => {
      state.adminToken = payload;
      state.isAuthenticate = true;
      state.loading = false;
    },
    isAuthenticateError: (state) => {
      state.loading = false;
      state.isAuthenticate = false;
      state.admin = null;
    },
    doctor_success: (state, { payload }) => {
      state.loading = false;
      state.hasError = false;
      state.doctors = payload.doctors; // Assuming payload contains the list of doctors
      state.message = payload.message || "Doctors fetched successfully.";
    },
    doctor_failure: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.message = payload || "Failed to fetch health admin"; // Correctly handle the message
    },
    one_doctor_sucess: (state, { payload }) => {
      state.loading = false;
      state.hasError = false;
      state.one_doctor = payload.doctor; // Assuming payload contains the list of doctors
      state.message = payload.message || "Doctors fetched successfully.";
    },
    one_doctor_Failure: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.message = payload || "Failed to fetch health admin"; // Correctly handle the message
    },
    one_banner_sucess: (state, { payload }) => {
      state.loading = false;
      state.hasError = false;
      state.one_banner = payload.banner; // Assuming payload contains the list of doctors
      state.message = payload.message;
    },
    one_banner_Failure: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.message = payload; // Correctly handle the message
    },
    one_health_admin_sucess: (state, { payload }) => {
      state.loading = false;
      state.hasError = false;
      state.one_health_admin = payload.admin; // Assuming payload contains the list of doctors
      state.message = payload.message || "Doctors fetched successfully.";
    },
    one_health_admin_Failure: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.message = payload || "Failed to fetch health admin"; // Correctly handle the message
    },
    health_admin_success: (state, { payload }) => {
      state.loading = false;
      state.hasError = false;
      state.health_admin = payload.data; // Assuming payload contains the list of doctors
      state.message = payload.message || "Health Admins fetched successfully.";
    },
    health_admin_failure: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.message = payload || "Failed to fetch health admin"; // Correctly handle the message
    },
    pharma_admin_success: (state, { payload }) => {
      state.loading = false;
      state.hasError = false;
      state.pharma_admin = payload.data; // Assuming payload contains the list of doctors
      state.message = payload.message || "Health Admins fetched successfully.";
    },
    pharma_admin_failure: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.message = payload || "Failed to fetch health admin"; // Correctly handle the message
    },
    addAdminSuccess: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      toast.success("Doctor added successfully!", {
        position: "top-center",
        autoClose: 1000,
      });
    },
    addAdminFailure: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = payload;
      toast.error(payload, {
        position: "top-center",
        autoClose: 2000,
      });
    },
    addpharmaAdminSuccess: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      toast.success("Pharma Admin added successfully!", {
        position: "top-center",
        autoClose: 1000,
      });
    },
    addpharmaAdminFailure: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = payload;
      toast.error(payload, {
        position: "top-center",
        autoClose: 2000,
      });
    },
    updateAdminSuccess: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      toast.success("Health Updated successfully!", {
        position: "top-center",
        autoClose: 1000,
      });
    },
    updateAdminFailure: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = payload;
      toast.error(payload, {
        position: "top-center",
        autoClose: 2000,
      });
    },
    updatePharmaAdminSuccess: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      toast.success("Pharma Admin Updated successfully!", {
        position: "top-center",
        autoClose: 1000,
      });
    },
    updatePharmaAdminFailure: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = payload;
      toast.error(payload, {
        position: "top-center",
        autoClose: 2000,
      });
    },
    one_pharma_admin_sucess: (state, { payload }) => {
      state.loading = false;
      state.hasError = false;
      state.one_pharma_admin = payload.admin; // Assuming payload contains the list of doctors
      state.message = payload.message || "Doctors fetched successfully.";
    },
    one_pharma_admin_Failure: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.message = payload || "Failed to fetch health admin"; // Correctly handle the message
    },
    patient_success: (state, { payload }) => {
      state.loading = false;
      state.hasError = false;
      state.patie = payload.patients; // Assuming payload contains the list of doctors
      state.message = payload.message || "Patients fetched successfully.";
    },
    patient_failure: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.message = payload || "Failed to fetch Patients"; // Correctly handle the message
    },
    banner_success: (state, { payload }) => {
      state.loading = false;
      state.hasError = false;
      state.banner = payload.banners; // Assuming payload contains the list of doctors
      state.message = payload.message || "Banners Fteched Successfully";
    },
    banner_failure: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.message = payload || "Failed to fetch Banners"; // Correctly handle the message
    },
    blog_success: (state, { payload }) => {
      state.loading = false;
      state.hasError = false;
      state.blog = payload.blogs; // Assuming payload contains the list of doctors
      state.message = payload.message || "Blogs Fteched Successfully";
    },
    blog_failure: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.message = payload || "Failed to fetch Blogs"; // Correctly handle the message
    },
    one_blog_sucess: (state, { payload }) => {
      state.loading = false;
      state.hasError = false;
      state.one_blog = payload.blog; // Assuming payload contains the list of doctors
      state.message = payload.message;
    },
    one_blog_Failure: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.message = payload; // Correctly handle the message
    },
    get_treatment_list: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.treatmentList = payload.services; // Correctly handle the message
    },
    get_packages_list: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.packagesList = payload.packages; // Correctly handle the message
    },
    get_packages_bookings_list: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.packageBookings = payload.packages; // Correctly handle the message
    },
    get_doctors_to_assign: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.doctorsData = payload.doctors; // Correctly handle the message
    },
  },
});

// Export actions and selectors
export const {
  getLoading,
  getUserFailure,
  getLocalStorageToken,
  isAuthenticateError,
  doctor_success,
  doctor_failure,
  addAdminSuccess,
  addAdminFailure,
  health_admin_success,
  health_admin_failure,
  one_doctor_sucess,
  one_doctor_Failure,
  updateAdminSuccess,
  updateAdminFailure,
  one_health_admin_sucess,
  one_health_admin_Failure,
  addpharmaAdminSuccess,
  addpharmaAdminFailure,
  pharma_admin_success,
  pharma_admin_failure,
  one_pharma_admin_sucess,
  one_pharma_admin_Failure,
  updatePharmaAdminSuccess, 
  updatePharmaAdminFailure,
  patient_success,
  patient_failure,
  banner_success,
  banner_failure,
  one_banner_sucess,
  one_banner_Failure,
  blog_success,
  blog_failure,
  one_blog_sucess,
  one_blog_Failure,get_treatment_list, get_packages_list, get_packages_bookings_list, get_doctors_to_assign
} = userSlice.actions;

export const adminSelector = (state) => state.admin;

export default userSlice.reducer;

// Admin login action
export const adminLogin = (values) => async (dispatch) => {
  dispatch(getLoading());
  try {
    const { data } = await axios.post(
      `${keyUri.BACKEND_URI}/admin/login`,
      values,
      config
    );
    console.log(data, "data");
    const token = data.token;

    if (token) {
      localStorage.setItem("token", token);
      dispatch(getLocalStorageToken(token));
    }
    console.log(token, "token");

    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });

    return data;
  } catch (error) {
    toast.error(error.response?.data?.message || "Login failed", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });

    dispatch(isAuthenticateError());
    return Promise.reject(error.response?.data);
  }
};

// Fetch all doctors action
export const fetchAllDoctors = () => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/fetch_all_doctors`,
      con
    );

    dispatch(doctor_success(data)); // Ensure correct structure
  } catch (error) {
    dispatch(
      doctor_failure(
        error.response?.data?.message || "Failed to fetch doctors."
      )
    );
  }
};

export const addhealthAdmin = (adminData) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/admin/add_health_admin",
      adminData,
      config
    );
    dispatch(fetchAllHealthAdmin());
    dispatch(addAdminSuccess(data));
  } catch (error) {
    dispatch(
      addAdminFailure(error.response?.data?.message || "Error adding doctor")
    );
  }
};

export const fetchAllHealthAdmin = () => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/fetch_all_health_admins`,
      con
    );
    console.log(data, "data");
    dispatch(health_admin_success(data)); // Ensure correct structure
  } catch (error) {
    dispatch(
      health_admin_failure(
        error.response?.data?.message || "Failed to fetch doctors."
      )
    );
  }
};

export const updateActiveStatus = (values) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json", // Since we're sending JSON data
        Authorization: `Bearer ${token}`,
      },
    };

    // Send the PUT request to update account status
    const { data } = await axios.put(
      `${keyUri.BACKEND_URI}/admin/update_status`, // URL to update status
      values, // The payload (e.g., { doctorId, account_status })
      config
    );

    console.log(data, "data");

    // Dispatch action to fetch all doctors after successful update
    dispatch(fetchAllDoctors());

    // Optionally, dispatch success if you want to show a toast message
    // dispatch(updateStatusSuccess(data));
  } catch (error) {
    // Dispatch failure if there's an error
    // dispatch(
    //   addAdminFailure(error.response?.data?.message || "Error updating status")
    // );
  }
};

export const fetchOneDoctors = (id) => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/get_doctor/${id}`,
      con
    );

    dispatch(one_doctor_sucess(data)); // Ensure correct structure
  } catch (error) {
    dispatch(
      one_health_admin_Failure(
        error.response?.data?.message || "Failed to fetch doctors."
      )
    );
  }
};

export const updateHealthAdminStatus = (values) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json", // Since we're sending JSON data
        Authorization: `Bearer ${token}`,
      },
    };

    // Send the PUT request to update account status
    const { data } = await axios.put(
      `${keyUri.BACKEND_URI}/admin/health_admin_update_status`, // URL to update status
      values, // The payload (e.g., { doctorId, account_status })
      config
    );

    console.log(data, "data");

    // Dispatch action to fetch all doctors after successful update
    dispatch(fetchAllHealthAdmin());

    // Optionally, dispatch success if you want to show a toast message
    // dispatch(updateStatusSuccess(data));
  } catch (error) {
    // Dispatch failure if there's an error
    // dispatch(
    //   addAdminFailure(error.response?.data?.message || "Error updating status")
    // );
  }
};

export const updateDoctorDetails = (values) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    // Send the PUT request to update account status
    const { data } = await axios.put(
      `${keyUri.BACKEND_URI}/admin/update_doctor`, // URL to update status
      values, // The payload (e.g., { doctorId, account_status })
      config
    );

    dispatch(updateAdminSuccess(data));

    console.log(data, "data");

    // Dispatch action to fetch all doctors after successful update
    // fetchOneDoctors(id);
    // Optionally, dispatch success if you want to show a toast message
    // dispatch(updateStatusSuccess(data));
  } catch (error) {
    dispatch(
      updateAdminFailure(error.response?.data?.message || "Error adding doctor")
    );

    // Dispatch failure if there's an error
    // dispatch(
    //   addAdminFailure(error.response?.data?.message || "Error updating status")
    // );
  }
};

export const fetchOneAdmin = (id) => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/get_health_admin/${id}`,
      con
    );

    dispatch(one_health_admin_sucess(data)); // Ensure correct structure
  } catch (error) {
    dispatch(
      doctor_failure(
        error.response?.data?.message || "Failed to fetch doctors."
      )
    );
  }
};

export const updateAdminHealth = (id, values) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    // Send the PUT request to update account status
    const { data } = await axios.put(
      `${keyUri.BACKEND_URI}/admin/update_healthadmin/${id}`, // URL to update status
      values, // The payload (e.g., { doctorId, account_status })
      config
    );

    dispatch(updateAdminSuccess(data));

    console.log(data, "data");

    // Dispatch action to fetch all doctors after successful update
    // fetchOneDoctors(id);
    // Optionally, dispatch success if you want to show a toast message
    // dispatch(updateStatusSuccess(data));
  } catch (error) {
    dispatch(
      updateAdminFailure(error.response?.data?.message || "Error adding doctor")
    );

    // Dispatch failure if there's an error
    // dispatch(
    //   addAdminFailure(error.response?.data?.message || "Error updating status")
    // );
  }
};

export const addpharmaAdmin = (adminData) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/admin/add_pharma_admin",
      adminData,
      config
    );
    dispatch(addpharmaAdminSuccess(data));

    // dispatch(fetchAllHealthAdmin());
  } catch (error) {
    dispatch(
      addpharmaAdminFailure(
        error.response?.data?.message || "Error adding Pharma Admin"
      )
    );
  }
};

export const fetchAllPharmaAdmin = () => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/fetch_all_pharma_admins`,
      con
    );
    console.log(data, "data");
    dispatch(pharma_admin_success(data)); // Ensure correct structure
  } catch (error) {
    dispatch(
      pharma_admin_failure(
        error.response?.data?.message || "Failed to fetch Pharma Admin"
      )
    );
  }
};

export const updatePharmaAdminStatus = (values) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json", // Since we're sending JSON data
        Authorization: `Bearer ${token}`,
      },
    };

    // Send the PUT request to update account status
    const { data } = await axios.put(
      `${keyUri.BACKEND_URI}/admin/pharma_update_status`, // URL to update status
      values, // The payload (e.g., { doctorId, account_status })
      config
    );

    console.log(data, "data");

    // Dispatch action to fetch all doctors after successful update
    dispatch(fetchAllPharmaAdmin());

    // Optionally, dispatch success if you want to show a toast message
    // dispatch(updateStatusSuccess(data));
  } catch (error) {
    // Dispatch failure if there's an error
    // dispatch(
    //   addAdminFailure(error.response?.data?.message || "Error updating status")
    // );
  }
};

export const fetchOnePharmaAdmin = (id) => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/get_pharma_admin/${id}`,
      con
    );

    dispatch(one_pharma_admin_sucess(data)); // Ensure correct structure
  } catch (error) {
    dispatch(
      one_pharma_admin_Failure(
        error.response?.data?.message || "Failed to fetch doctors."
      )
    );
  }
};

export const updatePharmaAdmin = (id, values) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    // Send the PUT request to update account status
    const { data } = await axios.put(
      `${keyUri.BACKEND_URI}/admin/update_pharmaadmin/${id}`, // URL to update status
      values, // The payload (e.g., { doctorId, account_status })
      config
    );

    dispatch(updatePharmaAdminSuccess(data));

    // Dispatch action to fetch all doctors after successful update
    // fetchOneDoctors(id);
    // Optionally, dispatch success if you want to show a toast message
    // dispatch(updateStatusSuccess(data));
  } catch (error) {
    dispatch(
      updatePharmaAdminFailure(
        error.response?.data?.message || "Error adding doctor"
      )
    );

    // Dispatch failure if there's an error
    // dispatch(
    //   addAdminFailure(error.response?.data?.message || "Error updating status")
    // );
  }
};

export const fetchAllPatients = () => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/fetch_all_patients`,
      con
    );
    console.log(data, "data");
    dispatch(patient_success(data)); // Ensure correct structure
  } catch (error) {
    dispatch(
      patient_failure(
        error.response?.data?.message || "Failed to fetch doctors."
      )
    );
  }
};

export const updatePatientStatus = (values) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json", // Since we're sending JSON data
        Authorization: `Bearer ${token}`,
      },
    };

    // Send the PUT request to update account status
    const { data } = await axios.put(
      `${keyUri.BACKEND_URI}/admin/patient_update_status`, // URL to update status
      values, // The payload (e.g., { doctorId, account_status })
      config
    );

    console.log(data, "data");

    // Dispatch action to fetch all doctors after successful update
    dispatch(fetchAllPatients());

    // Optionally, dispatch success if you want to show a toast message
    // dispatch(updateStatusSuccess(data));
  } catch (error) {
    // Dispatch failure if there's an error
    // dispatch(
    //   addAdminFailure(error.response?.data?.message || "Error updating status")
    // );
  }
};

export const addBanner = (values) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/admin/add_banner",
      values,
      config
    );
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
  } catch (error) {
    toast.error(error.response?.data?.message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
  }
};

export const getAllBanner = () => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/get_all_banners`,
      con
    );
    console.log(data, "data");
    dispatch(banner_success(data)); // Ensure correct structure
  } catch (error) {
    dispatch(
      banner_failure(error.response?.data?.message || "Failed to fetch banners")
    );
  }
};

export const updateBannerStatus = (values) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json", // Since we're sending JSON data
        Authorization: `Bearer ${token}`,
      },
    };

    // Send the PUT request to update account status
    const { data } = await axios.put(
      `${keyUri.BACKEND_URI}/admin/banner_status`, // URL to update status
      values, // The payload (e.g., { doctorId, account_status })
      config
    );

    console.log(data, "data");

    // Dispatch action to fetch all doctors after successful update
    dispatch(getAllBanner());

    // Optionally, dispatch success if you want to show a toast message
    // dispatch(updateStatusSuccess(data));
  } catch (error) {
    // Dispatch failure if there's an error
    // dispatch(
    //   addAdminFailure(error.response?.data?.message || "Error updating status")
    // );
  }
};

export const fetchOneBanner = (id) => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/get_one_banner/${id}`,
      con
    );

    dispatch(one_banner_sucess(data)); // Ensure correct structure
  } catch (error) {
    dispatch(
      one_banner_Failure(
        error.response?.data?.message || "Failed to fetch Banner"
      )
    );
  }
};

export const updateBannerDetails = (id, values) => async (dispatch) => {
  console.log(id, values, "values");
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    // Send the PUT request to update account status
    const { data } = await axios.put(
      `${keyUri.BACKEND_URI}/admin/update_banner/${id}`, // URL to update status
      values, // The payload (e.g., { doctorId, account_status })
      config
    );

    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
  } catch (error) {
    toast.error(error?.response?.data?.message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
  }
};

export const addBlog = (values) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/admin/add_blog",
      values,
      config
    );
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
  } catch (error) {
    toast.error(error.response?.data?.message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
  }
};

export const getAllBlog = () => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/get_all_blogs`,
      con
    );
    console.log(data, "data");
    dispatch(blog_success(data)); // Ensure correct structure
  } catch (error) {
    dispatch(
      blog_failure(error.response?.data?.message || "Failed to fetch blogs")
    );
  }
};

export const updateBlogStatus = (values) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json", // Since we're sending JSON data
        Authorization: `Bearer ${token}`,
      },
    };

    // Send the PUT request to update account status
    const { data } = await axios.put(
      `${keyUri.BACKEND_URI}/admin/blog_update_status`, // URL to update status
      values, // The payload (e.g., { doctorId, account_status })
      config
    );

    console.log(data, "data");

    // Dispatch action to fetch all doctors after successful update
    dispatch(getAllBlog());

    // Optionally, dispatch success if you want to show a toast message
    // dispatch(updateStatusSuccess(data));
  } catch (error) {
    // Dispatch failure if there's an error
    // dispatch(
    //   addAdminFailure(error.response?.data?.message || "Error updating status")
    // );
  }
};

export const fetchOneBlog = (id) => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/get_one_blog/${id}`,
      con
    );

    dispatch(one_blog_sucess(data)); // Ensure correct structure
  } catch (error) {
    dispatch(
      one_blog_Failure(error.response?.data?.message || "Failed to fetch Blog")
    );
  }
};

export const updateBlogDetails = (id, values) => async (dispatch) => {
  console.log(id, values, "values");
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    // Send the PUT request to update account status
    const { data } = await axios.put(
      `${keyUri.BACKEND_URI}/admin/update_blog/${id}`, // URL to update status
      values, // The payload (e.g., { doctorId, account_status })
      config
    );

    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
  } catch (error) {
    toast.error(error?.response?.data?.message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
  }
};

export const deleteBlog = (id) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json", // Since we're sending JSON data
        Authorization: `Bearer ${token}`,
      },
    };

    // Send the PUT request to update account status
    const { data } = await axios.delete(
      `${keyUri.BACKEND_URI}/admin/blog_delete/${id}`, // URL to update status
      config
    );

    console.log(data, "data");

    // Dispatch action to fetch all doctors after successful update
    dispatch(getAllBlog());

    // Optionally, dispatch success if you want to show a toast message
    // dispatch(updateStatusSuccess(data));
  } catch (error) {
    // Dispatch failure if there's an error
    // dispatch(
    //   addAdminFailure(error.response?.data?.message || "Error updating status")
    // );
  }
};

export const deleteBanner = (id) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json", // Since we're sending JSON data
        Authorization: `Bearer ${token}`,
      },
    };

    // Send the PUT request to update account status
    const { data } = await axios.delete(
      `${keyUri.BACKEND_URI}/admin/banner_delete/${id}`, // URL to update status
      config
    );

    console.log(data, "data");

    // Dispatch action to fetch all doctors after successful update
    dispatch(getAllBanner());

    // Optionally, dispatch success if you want to show a toast message
    // dispatch(updateStatusSuccess(data));
  } catch (error) {
    // Dispatch failure if there's an error
    // dispatch(
    //   addAdminFailure(error.response?.data?.message || "Error updating status")
    // );
  }
};

export const getAllTreatmentList = () => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/fetchAllActiveServices`,
      con
    );
    console.log(data, 'response');

    dispatch(get_treatment_list(data)); // Dispatch treatment list
  } catch (error) {
    dispatch(
      doctor_failure(
        error.response?.data?.message || "Failed to fetch treatments."
      )
    );
  }
};


export const addPackages = (adminData) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/admin/package_create",
      adminData,
      config
    );
    // dispatch(fetchAllHealthAdmin());
    // dispatch(addAdminSuccess(data));
  } catch (error) {
    dispatch(
      addAdminFailure(error.response?.data?.message || "Error adding doctor")
    );
  }
};


////////////// this line i am adding for the push of code

export const getAllPackages = () => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/getAllPackages`,
      con
    );
    console.log(data, 'response');

    dispatch(get_packages_list(data)); // Dispatch treatment list
  } catch (error) {
    dispatch(
      doctor_failure(
        error.response?.data?.message || "Failed to fetch treatments."
      )
    );
  }
};


export const getAllPackagesBookings = () => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/getAllPackagesBooking`,
      con
    );
    console.log(data, 'response');

    dispatch(get_packages_bookings_list(data)); // Dispatch treatment list
  } catch (error) {
    dispatch(
      doctor_failure(
        error.response?.data?.message || "Failed to fetch treatments."
      )
    );
  }
};

export const getDoctordToAssign = () => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/fetchAllDoctorsToAssign`,
      con
    );
    console.log(data, 'response');

    dispatch(get_doctors_to_assign(data)); // Dispatch treatment list
  } catch (error) {
    dispatch(
      doctor_failure(
        error.response?.data?.message || "Failed to fetch treatments."
      )
    );
  }
};

export const assignDoctorToPackageBook = (values) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json", // Since we're sending JSON data
        Authorization: `Bearer ${token}`,
      },
    };

    // Send the PUT request to update account status
    const { data } = await axios.post(
      `${keyUri.BACKEND_URI}/admin/updateDoctorToPackageBooking`, // URL to update status
      values, // The payload (e.g., { doctorId, account_status })
      config
    );

    console.log(data, "data");

    // Dispatch action to fetch all doctors after successful update
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });

    // Optionally, dispatch success if you want to show a toast message
    // dispatch(updateStatusSuccess(data));
  } catch (error) {
    toast.error(error.response?.data?.message || "Login failed", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
  }
};