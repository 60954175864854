import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsFilterRight } from "react-icons/bs";
import { CiExport } from "react-icons/ci";
import { Search } from "@mui/icons-material";
import Sidebar from "../../components/sidebar/Sidebar";
import MainSection from "../../components/mainSection/MainSection";
import { useDispatch, useSelector } from "react-redux";
import { getAllPackagesBookings, adminSelector, getDoctordToAssign, assignDoctorToPackageBook } from "../../api/auth";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Bookingslist = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { packageBookings, loading, doctorsData } = useSelector(adminSelector);

  
  const [packages, setPackages] = useState([]);
  const [doctors, setDoctors] = useState([]); // State for doctors
  const [selectedPackage, setSelectedPackage] = useState(null); // For the modal
  const [selectedDoctor, setSelectedDoctor] = useState(""); // For dropdown

  // Fetch packages on component mount
  useEffect(() => {
    dispatch(getAllPackagesBookings());
    dispatch(getDoctordToAssign());
  }, [dispatch]);

  // Update local state when Redux state changes
  useEffect(() => {
    if (packageBookings && Array.isArray(packageBookings)) {
      setPackages(packageBookings);
    }
  }, [packageBookings]);

  useEffect(() => {
    if (doctorsData && Array.isArray(doctorsData)) {
      setDoctors(doctorsData); // Store doctors data
    }
  }, [doctorsData]);
  
  console.log(doctorsData, 'wsxdcfvgbhnjmk');

  const [open, setOpen] = React.useState(false);

  const handleOpen = (pkg) => {
    // alert(JSON.stringify(pkg))
    localStorage.setItem("packageid", pkg._id)
    setSelectedPackage(pkg);
    // setOpen(true);
     navigate(`/assign-doctor/${pkg._id}`);
  };
const handleOpen1 = (pkg) => {
  // Instead of opening a modal, navigate to a new page
  navigate(`/assign-doctor/${pkg._id}`);
};

  const handleClose = () => {
    setOpen(false);
    setSelectedPackage(null);
  };

  const handleDropdownChange = (e) => {
    setSelectedDoctor(e.target.value); // Store selected doctor ID
  };

  const handleSubmit = async() => {
    console.log("Selected Doctor ID:", selectedDoctor);
    console.log("Selected Package:", selectedPackage);
    console.log(localStorage.getItem('packageid'));
    handleClose(); // Close the modal



    await dispatch(assignDoctorToPackageBook({
      _id: selectedPackage._id,
      doctorid: selectedDoctor
    }))
    // window.location.reload()

  };

   const [isAssigned, setIsAssigned] = useState(false);

   const handleToggleChange = () => {
     setIsAssigned((prevState) => !prevState);
   };

  return (
    <div className="p-6 bg-white rounded shadow-lg">
      <Sidebar />
      <MainSection>
        <div className="flex justify-between items-center mb-6 ">
          <h1 className="text-2xl font-bold">Package Bookings</h1>
        </div>

        <div className="flex flex-wrap justify-between items-center gap-4 mb-4">
          <div className="flex items-center w-full sm:w-1/2">
            <input
              type="search"
              placeholder="Search"
              className="border-2 rounded-xl p-2 w-full"
            />
            <Search className="text-gray-500 ml-2" />
          </div>
          <div className="flex space-x-4">
            <button className="border-2 rounded-xl px-4 py-2 flex items-center gap-2 hover:bg-gray-100">
              <BsFilterRight /> Filter
            </button>
            <button className="border-2 rounded-xl px-4 py-2 flex items-center gap-2 hover:bg-gray-100">
              <CiExport /> Export
            </button>
          </div>
        </div>

        <table className="table-auto w-full text-left border-collapse text-sm">
          <thead>
            <tr className="border-b bg-gray-100">
              <th className="p-2">Package ID</th>
              <th className="p-2">Package Name</th>
              <th className="p-2">User Name</th>
              <th className="p-2">User Phone Number</th>
              <th className="p-2">Package Price</th>
              <th className="p-2">Status</th>
              <th className="p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {packages.map((pkg) => (
              <tr key={pkg._id} className="border-b hover:bg-gray-50">
                <td className="p-2">AYUSH{pkg._id.slice(-4)}</td>
                <td className="p-2">{pkg.packagename}</td>
                <td className="p-2">{pkg.username}</td>
                <td className="p-2">{pkg.userPhone}</td>
                <td className="p-2">{pkg.paidAmt}</td>
                {/* <td
                  className={`p-2 ${
                    pkg.doctorid == null ? "text-red-600 font-bold" : "text-green-600 font-bold"
                  }`}
                >
                  {pkg.doctorid == null ? "Not Assigned" : "Assigned"}
                </td> */}
                <td className="p-2">
                  <label className="inline-flex items-center cursor-pointer">
                    {/* <span
                      className={`mr-2 ${
                        isAssigned ? "text-green-600" : "text-red-600"
                      } font-bold`}
                    >
                      {isAssigned ? "Assigned" : "Not Assigned"}
                    </span> */}
                    <div className="relative">
                      <input
                        type="checkbox"
                        checked={isAssigned}
                        onChange={handleToggleChange}
                        className="hidden"
                      />
                      <div
                        className={`w-12 h-6 rounded-full transition-colors duration-300 ${
                          isAssigned ? "bg-green-600" : "bg-red-600"
                        }`}
                      >
                        <div
                          className={`w-6 h-6 bg-white rounded-full transition-transform duration-300 transform ${
                            isAssigned ? "translate-x-6" : "translate-x-0"
                          }`}
                        ></div>
                      </div>
                    </div>
                  </label>
                </td>
                <td className="p-2 flex space-x-2">
                  <button
                    onClick={() => handleOpen(pkg)}
                    className="text-xl  px-4 py-1 "
                  >
                    <FaEdit />
                  </button>
                  {/* <button className="text-xl px-4 py-1 ">
                    <MdDeleteForever/>
                  </button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </MainSection>

      {/* Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            {selectedPackage
              ? `Assign Doctor for ${selectedPackage.packagename}`
              : "Assign Doctor"}
          </Typography>
          <div className="mt-4">
            <label htmlFor="doctor-dropdown" className="block mb-2 font-medium">
              Select Doctor
            </label>
            <select
              id="doctor-dropdown"
              className="border-2 rounded-xl p-2 w-full"
              onChange={handleDropdownChange}
            >
              {doctors.length > 0 ? (
                doctors.map((doctor) => {
                  console.log("Doctor in Map: ", doctor);
                  return (
                    <option key={doctor._id} value={doctor._id}>
                      {doctor.name}
                    </option>
                  );
                })
              ) : (
                <option>No Doctors Available</option>
              )}
            </select>
          </div>
          <div className="mt-4">
            <button
              onClick={handleSubmit}
              className="bg-rose-900 text-white px-4 py-2 rounded hover:bg-rose-800"
            >
              Update
            </button>
            <button
              onClick={handleClose}
              className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-500 ml-4"
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Bookingslist;
