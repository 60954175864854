import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Toggle from "react-toggle";
import "react-toastify/dist/ReactToastify.css";
import "react-toggle/style.css";
import { toast, ToastContainer } from "react-toastify";
import Sidebar from "../../components/sidebar/Sidebar";
import MainSection from "../../components/mainSection/MainSection";
import { useDispatch, useSelector } from "react-redux";

//
import {getAllTreatmentList, adminSelector, addPackages} from "../../api/auth"

const AddPackage = ({ onPackageAdd }) => {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const { treatmentList, loading } = useSelector(adminSelector);
  useEffect(() => {
    // Fetch the treatment list on component mount
    dispatch(getAllTreatmentList());
  }, [dispatch]);

  console.log(treatmentList, "treatment data");

    const [treatment, setTreatmentList] = useState(treatmentList || []);
    const [previewImage, setPreviewImage] = useState(null);
    const [profile, setProfile] = useState({ profileImage: null });
    useEffect(() => {
      setTreatmentList(treatmentList);
    
      // Initialize toggles with fetched treatment list
      if (treatmentList) {
        const initialToggles = {};
        treatmentList.forEach((treatment) => {
          initialToggles[treatment.serviceName] = false;
        });
        setFormData((prev) => ({ ...prev, toggles: initialToggles }));
      }
    }, [treatmentList]);
    

  const [formData, setFormData] = useState({
    packageName: "",
    description: "",
    price: "",
    discount:"",
    profileImage: null,
    image: null,
    toggles: {}, // Initialize as an empty object
  });
  

  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const { files } = e.target;
    const file = files[0];
    if (file) {
      // Update state with the selected file and set the preview image
      setProfile((prev) => ({ ...prev, profileImage: file }));
      setPreviewImage(URL.createObjectURL(file));
      setFormData({ ...formData, image: file });
    } else {
      // Optional: handle case when no file is selected (if needed)
      console.warn("No file selected");
    }
  };

  const handleRemoveImage = () => {
    setFormData((prev) => ({ ...prev, profileImage: null }));
    setPreviewImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleToggleChange = (serviceName) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      toggles: {
        ...prevFormData.toggles,
        [serviceName]: !prevFormData.toggles[serviceName],
      },
    }));
  };

  const handleCreatePackage = async () => {
    try {
      if (!formData.packageName || !formData.price || !formData.description) {
        toast.error("Please fill out all required fields!");
        return;
      }
  
      // Map selected service names to their IDs
      const selectedServiceIds = Object.keys(formData.toggles)
        .filter((key) => formData.toggles[key])
        .map((serviceName) => {
          const service = treatmentList.find(
            (treatment) => treatment.serviceName === serviceName
          );
          return service ? service._id : null; // Return the service ID
        })
        .filter((id) => id !== null); // Filter out any invalid/null IDs
  
      if (selectedServiceIds.length === 0) {
        toast.error("Please select at least one service!");
        return;
      }
  
      // Create FormData object for image upload
      const packageData = new FormData();
      packageData.append("packageName", formData.packageName);
      packageData.append("description", formData.description);
      packageData.append("price", formData.price);
  
      if (formData.image) {
        packageData.append("profileImage", formData.image);
      }
  
      packageData.append("services", JSON.stringify(selectedServiceIds)); // Append service IDs
  
      console.log("FormData being sent:");
      for (let pair of packageData.entries()) {
        console.log(pair[0], pair[1]); // Logs each key-value pair in FormData
      }
  
      console.log(packageData)
      // Example: POST the data to the server
      await dispatch(addPackages(packageData))
      setTimeout(() => {
        navigate(-1); // Navigate back after the delay
      }, 3000);
      // const response = await fetch("/api/packages/create", {
      //   method: "POST",
      //   body: packageData,
      // });
  
      // if (response.ok) {
      //   toast.success("Package created successfully!");
      //   navigate("/packages");
      // } else {
      //   const errorData = await response.json();
      //   toast.error(errorData.message || "Failed to create package");
      // }
    } catch (error) {
      console.error("Error creating package:", error);
      toast.error("An unexpected error occurred.");
    }
  };
  
  
  

  return (
    <div className="flex">
      {/* Sidebar Component */}
      {/* <div className="w-1/4"> */}
      <Sidebar />
      {/* </div> */}
      <MainSection>
        {/* Main Content */}
        <div className="p-6 bg-white rounded shadow-lg max-w-screen-lg mx-auto w-full">
          <ToastContainer />
          <div className="flex justify-between items-center mb-6 border-b-2 pb-2">
            <h1 className="text-2xl font-bold">Add Package</h1>
          </div>

          {/* Basic Details */}
          <h3 className="text-rose-900 font-semibold mb-4">Basic Details</h3>
          <div className="flex flex-col md:flex-row gap-6">
            {/* Left Section */}
            <div className="w-full md:w-1/2 border-r-2 pr-4">
              <label className="block mb-2 font-medium">Thumbnail Image</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="border rounded p-2 w-full mb-4 hover:bg-gray-100 transition"
              />

              <label className="block mb-2 font-medium">Package Name</label>
              <input
                type="text"
                name="packageName"
                placeholder="Enter package name"
                value={formData.packageName}
                onChange={(e) =>
                  setFormData({ ...formData, packageName: e.target.value })
                }
                className="border rounded p-2 w-full mb-4"
              />

              <label className="block mb-2 font-medium">Description</label>
              <textarea
                name="description"
                placeholder="Enter product description"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                className="border rounded p-2 w-full mb-4 h-28 resize-none"
              />

              <label className="block mb-2 font-medium">Price</label>
              <input
                type="number"
                name="price"
                placeholder="Enter package price"
                value={formData.price}
                onChange={(e) =>
                  setFormData({ ...formData, price: e.target.value })
                }
                className="border rounded p-2 w-full"
              />
              <label className="block mb-2 font-medium"> Discount Price</label>
              <input
                type="number"
                name="discount"
                placeholder="Enter Discount Price"
                value={formData.discount}
                onChange={(e) =>
                  setFormData({ ...formData, discount: e.target.value })
                }
                className="border rounded p-2 w-full"
              />
            </div>

            {/* Right Section */}
            {/* <div className="w-full md:w-1/2">
              <h3 className="mb-4 font-semibold text-md">Package Inclusions</h3>
              <div className="flex flex-col gap-4">
                {loading ? (
                  <p>Loading treatments...</p>
                ) : (
                  treatmentList.map((treatment) => (
                    <div
                      key={treatment.name}
                      className="flex items-center justify-between border-2 rounded-md p-3"
                    >
                      <label className="text-md font-medium">
                        {treatment.serviceName}
                      </label>
                      <Toggle
                        checked={formData.toggles[treatment.name]}
                        onChange={() => handleToggleChange(treatment.name)}
                      />
                    </div>
                  ))
                )}
              </div>
            </div> */}
            <div className="w-full md:w-1/2">
              <h3 className="mb-4 font-semibold text-md">Package Inclusions</h3>
              <div className="flex flex-col gap-4">
                {loading ? (
                  <p>Loading treatments...</p>
                ) : (
                  Array.isArray(treatmentList) &&
                  treatmentList.map((treatment) => (
                    <div
                      key={treatment.name}
                      className="flex items-center justify-between border-2 rounded-md p-3"
                    >
                      <label className="text-md font-medium">
                        {treatment.serviceName}
                      </label>
                      <Toggle
                        checked={
                          formData.toggles[treatment.serviceName] || false
                        }
                        onChange={() =>
                          handleToggleChange(treatment.serviceName)
                        }
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-end mt-6">
            <button
              onClick={handleCreatePackage}
              className="bg-rose-900 text-white font-medium rounded px-6 py-2 hover:bg-blue-500 transition duration-300"
            >
              Create Package
            </button>
          </div>
        </div>
      </MainSection>
    </div>
  );
};

AddPackage.defaultProps = {
  onPackageAdd: () => console.warn("onPackageAdd is not provided!"),
};

export default AddPackage;
