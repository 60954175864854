import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsFilterRight } from "react-icons/bs";
import { CiExport } from "react-icons/ci";
import { Search } from "@mui/icons-material";
import Sidebar from "../../components/sidebar/Sidebar";
import MainSection from "../../components/mainSection/MainSection";
import { useDispatch, useSelector } from "react-redux";
import { getAllPackages, adminSelector } from "../../api/auth";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";

const ManagePackages = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { packagesList, loading } = useSelector(adminSelector);

  const [packages, setPackages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  // Fetch packages on component mount
  useEffect(() => {
    dispatch(getAllPackages());
  }, [dispatch]);

  // Update local state when Redux state changes
  useEffect(() => {
    if (packagesList && Array.isArray(packagesList)) {
      setPackages(packagesList);
    }
  }, [packagesList]);

  const handleEditPackage = (pkg) => {
    setSelectedPackage(pkg);
    setIsModalOpen(true);
  };

  const handleSavePackage = (updatedPackage) => {
    // Update the package locally (or you could dispatch an action to update it on the server)
    setPackages((prevPackages) =>
      prevPackages.map((pkg) =>
        pkg._id === updatedPackage._id ? updatedPackage : pkg
      )
    );
    setIsModalOpen(false); // Close modal after save
  };

  const handleToggle = (pkgId) => {
    setPackages((prevPackages) =>
      prevPackages.map((pkg) =>
        pkg._id === pkgId ? { ...pkg, isActive: !pkg.isActive } : pkg
      )
    );
  };

  return (
    <div className="p-6 bg-white rounded shadow-lg">
      <Sidebar />
      <MainSection>
        <div className="flex justify-between items-center mb-6 ">
          <h1 className="text-2xl font-bold">Package Management</h1>
          <button
            onClick={() => navigate("/create-new-package")}
            className="p-2 px-4 bg-rose-900 text-white rounded-xl hover:bg-rose-700 transition-all"
          >
            Add Package
          </button>
        </div>

        <div className="flex flex-wrap justify-between items-center gap-4 mb-4">
          <div className="flex items-center w-full sm:w-1/2">
            <input
              type="search"
              placeholder="Search"
              className="border-2 rounded-xl p-2 w-full"
            />
            <Search className="text-gray-500 ml-2" />
          </div>
          <div className="flex space-x-4">
            <button className="border-2 rounded-xl px-4 py-2 flex items-center gap-2 hover:bg-gray-100">
              <BsFilterRight /> Filter
            </button>
            <button className="border-2 rounded-xl px-4 py-2 flex items-center gap-2 hover:bg-gray-100">
              <CiExport /> Export
            </button>
          </div>
        </div>

        <table className="table-auto w-full text-left border-collapse text-sm">
          <thead>
            <tr className="border-b bg-gray-100">
              <th className="p-2">Package ID</th>
              <th className="p-2">Package Name</th>
              <th className="p-2">Image</th>
              <th className="p-2">Package Inclusions</th>
              <th className="p-2">Package Price</th>
              <th className="p-2">Status</th>
              <th className="p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {packages.map((pkg) => (
              <tr key={pkg._id} className="border-b hover:bg-gray-50">
                <td className="p-2">AYUSH{pkg._id.slice(-4)}</td>
                <td className="p-2">{pkg.packageName}</td>
                <td className="p-2">
                  {pkg.image ? (
                    <img
                      src={pkg.image}
                      alt="Package"
                      className="w-16 h-16 object-cover rounded"
                    />
                  ) : (
                    "N/A"
                  )}
                </td>
                <td className="p-2">
                  {pkg.services && pkg.services.length > 0
                    ? pkg.services.join(", ")
                    : "N/A"}
                </td>
                <td className="p-2">₹{pkg.price || "0.00"}</td>
                <td className="p-2">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={pkg.isActive}
                      onChange={() => handleToggle(pkg._id)}
                      className="hidden"
                    />

                    <div
                      className={`w-10 h-6 ${
                        pkg.isActive ? "bg-green-500" : "bg-red-500"
                      } rounded-full relative transition-colors`}
                    >
                      <div
                        className={`dot absolute top-1 left-1 w-4 h-4 bg-white rounded-full transition-transform ${
                          pkg.isActive ? "translate-x-4" : ""
                        }`}
                      ></div>
                    </div>
                  </label>
                </td>

                <td className="p-2 flex space-x-2">
                  <button
                    onClick={() => handleEditPackage(pkg)}
                    className="text-xl px-4 py-1"
                  >
                    <FaEdit />
                  </button>
                  {/* Add delete functionality here */}
                  {/* <button className="text-xl px-4 py-1">
                    <MdDeleteForever />
                  </button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </MainSection>

      {/* Modal for editing package */}
      {isModalOpen && selectedPackage && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-2xl mb-4">Edit Package</h2>
            <div className="mb-4">
              <label className="block">Package Name</label>
              <input
                type="text"
                name="packageName"
                value={selectedPackage.packageName}
                onChange={(e) => {
                  setSelectedPackage({
                    ...selectedPackage,
                    packageName: e.target.value,
                  });
                }}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block">Package Image URL</label>
              <input
                type="text"
                name="image"
                value={selectedPackage.image}
                onChange={(e) => {
                  setSelectedPackage({
                    ...selectedPackage,
                    image: e.target.value,
                  });
                }}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block">Package Inclusions</label>
              <input
                type="text"
                name="services"
                value={selectedPackage.services.join(", ")}
                onChange={(e) => {
                  setSelectedPackage({
                    ...selectedPackage,
                    services: e.target.value.split(", "),
                  });
                }}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block">Package Price</label>
              <input
                type="number"
                name="price"
                value={selectedPackage.price}
                onChange={(e) => {
                  setSelectedPackage({
                    ...selectedPackage,
                    price: e.target.value,
                  });
                }}
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 bg-gray-300 rounded"
              >
                Cancel
              </button>
              <button
                onClick={() => handleSavePackage(selectedPackage)}
                className="px-4 py-2 bg-rose-900 text-white rounded"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManagePackages;
